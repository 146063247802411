import { FormGroup } from "@material-ui/core";
import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  TextField,
} from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import axios from "axios";
import { useSnackbar } from "notistack";
import { useState } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";

const CreateDialog = (props) => {
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();
  const [user, setUser] = useState({
    hospitalId: "",
    username: "",
    customerId: "",
    phoneNumber: "",
    agentDivision: "",
    has_recommended: false,
    hasAgreedConsent: false,
  });

  const getAllAgents = async () => {
    const response = await fetch(
      `${process.env.REACT_APP_BACKEND_URL}/api/agents`,
      {
        method: "GET",
      }
    );

    const data = await response.json();

    return data.data;
  };

  const { data: agents } = useQuery("getAllAgents", getAllAgents);

  const getData = (value) => {
    return queryClient.invalidateQueries("getAllData");
  };

  const { mutate: mutateGetData } = useMutation(getData);

  const createUser = async () => {
    console.log(user);

    try {
      const userCreate = await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/api/user/create`,
        user,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      console.log(userCreate.data);
      mutateGetData();
      enqueueSnackbar("User created", { variant: "success" });
      props.onClose();
    } catch (err) {
      enqueueSnackbar(err.response.data.message, { variant: "error" });
      console.log(err);
      return err;
    }
  };

  const {
    mutate: mutateCreateUser,
    isLoading: isLoadingCreateUser,
  } = useMutation(createUser);

  const handleCreateUser = () => {
    try {
      console.log("creating user");
      mutateCreateUser(user);
    } catch (error) {
      console.log(`Handle Change User Info Error: ${error}`);
    } finally {
      //   props.onClose();
    }
  };

  return (
    <Dialog open={props.open} onClose={props.onClose}>
      <DialogTitle>Tambah User</DialogTitle>
      <DialogContent>
        <Box sx={{ width: "100%" }}>
          <TextField
            autoFocus
            margin={"normal"}
            label="Hospital ID"
            type="text"
            fullWidth
            onChange={(e) => setUser({ ...user, hospitalId: e.target.value })}
          />
          <TextField
            margin="normal"
            label="Username"
            type="text"
            fullWidth
            onChange={(e) => setUser({ ...user, username: e.target.value })}
          />
          <TextField
            margin="normal"
            label="Nomor Telepon"
            type="text"
            inputProps={{ inputMode: "numeric", pattern: "[0-9]*" }}
            fullWidth
            onChange={(e) =>
              setUser({
                ...user,
                phoneNumber: e.target.value,
                customerId: e.target.value,
              })
            }
          />
          <Autocomplete
            style={{ marginTop: "10px" }}
            id="tags-standard"
            margin={"normal"}
            fullWidth
            options={agents}
            getOptionLabel={(option) => option.agentName}
            onChange={(_, newValue) => {
              const agentDivision = newValue.agentDivison;
              console.log(agentDivision, newValue);
              setUser({ ...user, agentDivision });
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="standard"
                label="Agent Division"
                placeholder="Pilih Agent"
              />
            )}
          />
          <FormGroup style={{ marginTop: "20px" }}>
            <FormControlLabel
              control={
                <Checkbox
                  color="primary"
                  size="small"
                  onChange={(e) =>
                    setUser({ ...user, has_recommended: e.target.checked })
                  }
                />
              }
              label="Has recommended"
            />
            <FormControlLabel
              control={
                <Checkbox
                  color="primary"
                  size="small"
                  onChange={(e) =>
                    setUser({ ...user, hasAgreedConsent: e.target.checked })
                  }
                />
              }
              label="Has agree"
            />
          </FormGroup>
        </Box>
      </DialogContent>
      <DialogActions
        style={{
          padding: "30px",
        }}
      >
        <Button
          onClick={handleCreateUser}
          disabled={isLoadingCreateUser}
          variant={"contained"}
          color={"primary"}
        >
          Create
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CreateDialog;
