import { makeStyles, Typography } from "@material-ui/core";
import { Box } from "@material-ui/core";
import { Container } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.default,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    margin: theme.spacing(6, 0),
  },
  titleBox: {
    margin: theme.spacing(4, 0, 0),
  },
}));

const TermsCondition = () => {
  const classes = useStyles();
  return (
    <Box className={classes.root}>
      <Container maxWidth="md">
        <Box>
          <Typography align="center" color="textPrimary" variant="h2">
            Syarat dan Ketentuan
          </Typography>
          <Typography
            color="textSecondary"
            align="center"
            gutterBottom
            variant="body1"
          >
            Layanan Nutricia Medical (NUTRIMED)
          </Typography>
          <Typography
            align="justify"
            color="textSecondary"
            gutterBottom
            variant="body2"
            className={classes.titleBox}
          >
            Mohon Anda membaca Syarat dan Ketentuan ini secara seksama sebelum
            mengakses atau menggunakan Layanan Nutricia Medical (Nutrimed)
            (selanjutnya disebut “Layanan”) ini. Dengan mengakses atau
            menggunakan Layanan ini, Anda telah membaca secara seksama, mengerti
            dan menyetujui syarat-syarat dan ketentuan-ketentuan, termasuk
            Pernyataan Privasi, jika ada, serta batasan hukum yang berlaku
            sehubungan dengan Layanan ini (selanjutnya disebut “Syarat dan
            Ketentuan”). Jika Anda tidak berkenan untuk tunduk pada Syarat dan
            Ketentuan ini, mohon untuk tidak mengakses Layanan.
          </Typography>
          <Typography
            align="justify"
            color="textSecondary"
            gutterBottom
            variant="body2"
          >
            Kami berhak sewaktu waktu untuk mengubah Syarat dan Ketentuan ini,
            dan perubahan tersebut berlaku efektif setelah di posting melalui
            Layanan ataupun platform terkait Layanan dan akan diberikan melalui
            pemberitahuan melalui Layanan. Anda, sebagai pengguna Layanan ini
            (“Pengguna”), bertanggung jawab untuk membaca secara seksama
            ketentuan tersebut setiap kali menggunakan atau mengakses Layanan.
            Dengan mengakses atau menggunakan Layanan ini lebih lanjut merupakan
            bentuk persetujuan atas perubahan Syarat dan Ketentuan pada Layanan.
          </Typography>
          <Typography
            align="justify"
            color="textSecondary"
            gutterBottom
            variant="body2"
          >
            Kami juga berhak untuk mengganti, mengubah, menangguhkan dan/atau
            menghentikan semua atau bagian apapun dari Layanan ini setiap saat
            atau setelah memberikan pemberitahuan sebagaimana dipersyaratkan
            oleh undang-undang dan peraturan yang berlaku. Kami dapat
            meluncurkan layanan tertentu atau fitur tertentu dalam versi beta,
            yang mungkin tidak berfungsi dengan baik atau sama seperti versi
            akhir dan kami tidak bertanggung jawab dalam hal demikian. Kami juga
            dapat membatasi fitur tertentu atau membatasi akses Anda ke bagian
            atau seluruh layanan atas kebijakannya sendiri dan tanpa
            pemberitahuan atau kewajiban. Kami berhak untuk menolak memberikan
            akses ke Layanan kepada Anda atau mengizinkan Anda untuk membuka
            Akun dengan alasan apapun.
          </Typography>
          <Typography
            align="justify"
            color="textSecondary"
            gutterBottom
            variant="body2"
          >
            Syarat dan Ketentuan pengaksesan dan penggunaan Layanan adalah
            sebagai berikut:
          </Typography>
          <Typography
            align="justify"
            color="textSecondary"
            gutterBottom
            variant="body2"
          >
            1. UMUM
            <br></br>
            Layanan Nutricia Medical ini adalah Layanan resmi yang dimiliki dan
            dikelola oleh PT Sarihusada Generasi Mahardhika, berkedudukan di
            Yogyakarta, PT Nutricia Indonesia Sejahtera, berkedudukan di Jakarta
            Timur , selanjutnya secara bersama-sama disebut “Danone” atau
            “Kami”) serta Distributor kami Tigaraksa.
            <br></br>
            Layanan ini dapat digunakan oleh Pengguna untuk mendapatkan
            informasi produk Danone melalui Careline dan melakukan pemesanan
            produk Danone ke pihak Distributor bagi bidan.
            <br></br>
            Ketentuan lebih lanjut mengenai persyaratan Pengguna mengacu kepada
            ketentuan 4 Syarat dan Ketentuan ini.
            <br></br>
            Syarat dan Ketentuan Layanan ini merupakan bagian tidak terpisahkan
            dengan Kebijakan dan peraturan lainnya yang dimuat pada Layanan.
            <br></br>
            <br></br>
            2. Tentang Layanan
            <br></br>
            Layanan ini merupakan sebuah layanan dengan fitur otomatis untuk
            menjawab pesan pengguna melalui media komunikasi Nomor WhatsApp
            Business kami. Layanan ini membantu Pengguna untuk melakukan
            pencarian produk bagi anak berkebutuhan khusus di apotek terdekat
            dalam jaringan distribusi produk Danone.
            <br></br>
            Adapun fungsi utama Layanan ini adalah sebagai berikut:
            <br></br>
            <ol type="1">
              <li>
                Memberikan produk informasi melalui Careline yang akan
                menghubungi Anda melalui media komunikasi WhatsApp Business
              </li>
              <li>
                Membantu proses pemesanan produk Danone ke pihak distributor
                Tigaraksadengan menggunakan fitur otomatis (“Chatbot”) melalui
                media komunikasi nomor WhatsApp Business.
              </li>
              <li>
                Mengolah data pemesanan produk dari Chatbot ke pihak Distributor
                ke Apotek untuk mengonfirmasi pesanan berdasarkan ketersediaan
                stok
              </li>
              <li>
                Menginfokan status pemesanan ketika produk sudah dikonfirmasi,
                Anda akan membayar ke nomor rekening yang tercantum serta akan
                dikirimkan produk sesuai ketersediaan yang ada.
              </li>
            </ol>
            <br></br>
            <br></br>
            Cara mendapatkan dan mengakses Layanan ini
            <br></br>
            <ol type="i">
              <li>
                Layanan digunakan oleh Pengguna melalui media komunikasi Whats
                App yang telah di unduh terlebih dahulu ke dalam Smartphone
                pengguna.
              </li>
              <li>
                Pengguna akan dihubungi melalui WhatsApp Business oleh Careline
                untuk diberikan produk informasi dan melakukan proses pemesanan
                produk yang akan dijawab otomatis oleh Chatbot. dan/atau
                Pengguna bisa menambahkan secara langsung nomor Whats App
                Business Danone ke dalam daftar kontak What’s App pengguna.
              </li>
              <li>
                Pengguna wajib untuk membaca dan menyetujui Syarat dan Ketentuan
                serta Pernyataan Privasi sebelum menggunakan Layanan ini.
              </li>
            </ol>
            <br></br>
            <br></br>
            3. Whats app Akun dan Keamanan AKUN
            <br></br>
            Syarat dan Ketentuan serta Kebijakan Privasi terkait dengan
            penggunaan Whatsapp mohon merujuk ke:
            <br></br>
            https://www.whatsapp.com/legal/ https://www.whatsapp.com/privacy
            <br></br>
            Anda sebagai Pengguna sepenuhnya bertanggung jawab untuk semua
            kegiatan yang dilakukan dengan menggunakan Whats App Akun Anda,
            bahkan bila kegiatan atau penggunaan tersebut tidak dilakukan oleh
            Anda. Danone tidak akan bertanggung jawab untuk setiap kerugian atau
            kerusakan yang timbul dari penggunaan tanpa izin atas Whats App Akun
            Anda atau kegagalan Anda untuk mematuhi ketentuan ini.
            <br></br>
            Anda setuju bahwa untuk alasan apapun atas kebijakan Danone sendiri
            dan dengan ataupun tanpa pemberitahuan atau kebijakan kepada Anda
            atau pihak ketiga mana pun, Danone dapat mengakhiri Layanan untuk
            Akun Pengguna Anda serta menghapus atau membuang setiap konten yang
            berkaitan dengan Akun Pengguna Anda dari Layanan. Dasar untuk
            pengakhiran tersebut dapat termasuk, tetapi tidak terbatas pada :
            (a) tidak aktif dalam jangka waktu lama; (b) pelanggaran terhadap
            ketentuan Syarat dan Ketentuan ini; (c) adanya pemalsuan, perilaku
            yang ilegal, menipu, melecehkan, memfitnah, mengancam dan/atau kasar
            dan/atau pelanggaran hukum lainnya; (d) memiliki beberapa akun
            pengguna untuk alasan yang tidak sah; dan/atau (e) perilaku yang
            merugikan Pengguna lain, pihak ketiga, atau kepentingan bisnis
            Danone. Penggunaan Akun untuk tujuan yang ilegal, menipu,
            melecehkan, memfitnah, mengancam dan/atau pelanggaran hukum lainnya,
            dapat dirujuk ke pihak penegak hukum tanpa pemberitahuan kepada
            Anda. Apabila permasalahan hukum timbul atau tindakan penegakan
            hukum dilakukan dalam kaitan dengan Akun anda atau penggunaan
            layanan pada Layanan oleh Anda untuk alasan apapun, dapat mengakhiri
            Akun Anda dengan segera dengan atau tanpa pemberitahuan.
            <br></br>
            Anda dapat meminta Danone untuk menghapus Akun atau Nomor Handphone
            Anda dengan memberi tahu Danone tentang niatan tersebut. Info lebih
            lanjut, kontak ke Bagian Bantuan dan Dukungan.
            <br></br>
            <br></br>
            4. Syarat dan Ketentuan PENGGUNA
            <br></br>
            <ol type="a">
              <li>
                Anda sebagai Pengguna dengan ini menyatakan bahwa Anda adalah
                orang yang cakap dan mampu untuk mengikatkan dirinya dalam
                sebuah perjanjian yang sah menurut hukum dan telah membaca
                seluruh Syarat dan Ketentuan.
              </li>
              <li>
                Pengunduhan Whats App Layanan dan biaya pengiriman pesan ke
                nomor Whats App Business Danone oleh Pengguna dilakukan atas
                biaya Pengguna sendiri.
              </li>
              <li>
                HN Careline: Pengguna memahami sepenuhnya bahwa Layanan ini
                hanya memberikan informasi produk Danone
              </li>
              <li>
                Chatbot : Pengguna memahami sepenuhnya bahwa Layanan ini
                menggunakan balasan otomatis, untuk membantu merekap dan
                memproses pesanan produk Anda. Produk yang dipesan akan
                dikonfirmasi ketersediaan produknya H+1 oleh pihak Distributor
                Tigaraksa dan setelah pembayaran akan dikirimkan oleh pihak
                Distributor Tigaraksa
              </li>
              <li>
                Pengguna akan diminta untuk memberikan informasi pribadi
                Pengguna yang dapat meliputi data-data sebagai berikut atau
                data-data lain yang diperlukan dalam penyelenggaran Layanan yang
                berkaitan dengan layanan ini:
                <ul>
                  <li>Nama,</li>
                  <li>Nomor WhatsApp,</li>
                  <li>Lokasi pengguna</li>
                </ul>
              </li>
              <li>
                Pengguna harus menyampaikan kepada Danone, informasi yang
                akurat, lengkap dan tidak menyesatkan.
              </li>
              <li>
                Danone berhak meminta dokumentasi untuk melakukan verifikasi
                informasi yang Pengguna berikan. Jika Pengguna memilih untuk
                tidak mengirimkan informasi pribadi Pengguna kepada Danone atau
                kemudian menarik persetujuan menggunakan informasi pribadi
                Pengguna, maka hal itu dapat menyebabkan Danone tidak dapat
                menyediakan Layanan kepada Pengguna.
              </li>
              <li>
                Danone hanya akan menggunakan nama Pengguna dan informasi yang
                berhubungan dengan Pengguna sebagaimana yang dinyatakan dalam
                kebijakan privasi pada Syarat dan Ketentuan ini. Danone hanya
                akan mengumpulkan informasi yang diperlukan dan relevan dengan
                transaksi antara Danone dengan Pengguna.
              </li>
              <li>
                Jika Pengguna memiliki pertanyaan atau keluhan mengenai Layanan
                ini atau produk lain silahkan menghubungi melalui Careline
                Danone.
              </li>
              <li>
                Pengguna bertanggung jawab atas semua kegiatan yang terjadi
                dengan penggunaan nama dan akun miliknya. Pengguna juga
                bertanggung jawab untuk segera memberitahu Danone bila nama
                dan/atau akun Pengguna telah digunakan secara tidak sah oleh
                pihak lain atau terjadi pelanggaran keamanan lainnya.
              </li>
              <li>
                Pengguna dilarang untuk menyalahgunakan Layanan ini dan/atau
                setiap akses terhadap Layanan ini untuk hal-hal yang dilarang
                sesuai dengan kebijakan dan peraturan yang berlaku di Republik
                Indonesia.
              </li>
              <li>
                Kami berhak sewaktu-waktu meminta Pengguna untuk memperbaiki
                akun Pengguna jika diperlukan demi keamanan akses.
              </li>
              <li>
                Dengan memberikan data diri Anda sebagaimana dimaksud dalam
                Angka 4 (d) Syarat dan Ketentuan ini (“Informasi Pribadi”)
                melalui Layanan, Anda sebagai Pengguna memberikan persetujuan
                kepada Danone untuk:
                <ol type="i">
                  <li>
                    Menyimpan dan menggunakan Informasi Pribadi, termasuk namun
                    tidak terbatas pada : nama, alamat, telepon/No Handphone,
                    Lokasi, dan sebagainya untuk memverifikasi data diri Anda,
                    untuk keperluan Layanan ini dan/atau menghubungi Anda untuk
                    memberikan informasi dan tips-tips terkait Produk.
                  </li>
                  <li>
                    Mengirimkan informasi kepada Anda dengan cara apapun (surat,
                    email, SMS, telepon, dan lain-lain) komunikasi yang
                    berhubungan dengan Produk, termasuk namun tidak terbatas
                    pada informasi dan materi pada peluncuran merek atau produk,
                    perubahan kemasan produk, kegiatan pemasaran dan/atau
                    peraturan Produk Danone, sepanjang diizinkan oleh hukum yang
                    berlaku;
                  </li>
                  <li>
                    Memproses Informasi Pribadi di negara selain negara di mana
                    Anda mendaftar (sebagai contoh, lokasi database di satu
                    negara dan mengakses database jarak jauh dari negara lain);
                  </li>
                  <li>
                    Mengungkapkan Informasi Pribadi kepada penyedia layanan
                    Danone untuk tujuan di atas dan/atau afiliasi Danone
                    dan/atau pihak ketiga yang ditunjuk oleh Danone untuk
                    melakukan hal-hal di atas untuk tujuannya sendiri serta
                    (jika diperlukan oleh hukum) kepada pihak yang berwenang;
                    dan/atau
                  </li>
                  <li>
                    Menggunakan tanggapan, komentar, gambar, cerita dan/atau
                    tulisan yang Anda kirimkan ke Layanan, jika ada, untuk
                    tujuan apapun, termasuk namun tidak terbatas untuk materi
                    komunikasi atau promosi internal dan eksternal Danone. n.
                    Informasi Pribadi yang diberikan sukarela oleh Pengguna
                    kepada Danone melalui Layanan ini, mungkin akan dipergunakan
                    oleh Danone, baik secara langsung maupun melalui pihak
                    ketiga, guna menghubungi Pengguna untuk menawarkan produk
                    atau layanan terpilih, kecuali Pengguna menyatakan tidak
                    bersedia menerima penawaran pada formulir langganan.
                    Informasi tersebut mungkin juga dipakai secara internal di
                    Indonesia untuk menilai fungsi Layanan ini dan kebutuhan
                    Pengguna.
                  </li>
                </ol>
              </li>
            </ol>
            5. PENGGUNAAN LAYANAN
            <br></br>
            <ol type="a">
              <li>
                Layanan ini beserta seluruh isi/konten atau materi di dalamnya
                hanya boleh digunakan untuk keperluan pribadi Pengguna, bukan
                untuk tujuan komersial.
              </li>
              <li>
                Pengguna tidak dapat mendistribusikan, memodifikasi,
                mentransmisikan, me-repost atau menggunakan materi dalam Layanan
                ini untuk tujuan umum atau komersial tanpa persetujuan tertulis
                terlebih dahulu dari Danone. Semua hak cipta dan hak kepemilikan
                lainnya dalam materi yang diunduh (downloaded material) atau di
                capture harus dijaga dan dipertahankan.
              </li>
              <li>
                Penggunaan Layanan ini adalah dengan risiko pribadi
                Pengguna. Danone atau pihak lain yang terlibat dalam penciptaan,
                pembuatan dan/atau pengelolaan Layanan ini tidak bertanggung
                jawab atas segala kerugian langsung, tidak disengaja,
                konsekuensial, tidak langsung atau kerugian yang diakibatkan
                oleh akses dan penggunaan Layanan ini, termasuk namun tidak
                terbatas pada: kerusakan, atau virus yang mungkin menginfeksi
                peralatan komputer atau mobile Pengguna. Tanpa membatasi
                ketentuan sebelumnya, segala sesuatu pada Layanan ini disediakan
                untuk Pengguna 'apa adanya' tanpa jaminan isi atau jaminan
                keberhargaannya serta kesesuaiannya untuk tujuan tertentu, atau
                tidak ada jaminan bebas dari pelanggaran hukum.
              </li>
            </ol>
            6. HAK KEKAYAAN INTELEKTUAL
            <br></br>
            <ol type="a">
              <li>
                Semua isi dari Layanan ini, termasuk namun tidak terbatas pada
                teks, foto, grafik, materi audio, perangkat lunak maupun bentuk
                lainnya adalah hak cipta milik Danone dan/atau afiliasinya
                dan/atau pihak ketiga lain yang ditunjuk oleh Danone, kecuali
                dinyatakan lain, dan tidak dapat digunakan tanpa izin tertulis
                dari Danone atau pemilik hak tersebut. Gambar yang ditampilkan
                di Layanan merupakan milik, atau digunakan harus dengan izin
                oleh Danone. Penggunaan dan/atau modifikasi pada gambar-gambar
                tersebut oleh Anda sangat dilarang. Penggunaan yang tidak sah
                dari gambar dapat melanggar hak cipta, merek, privasi dan/atau
                peraturan yang berlaku dapat dikenakan sanksi hukum sesuai
                ketentuan yang berlaku. Konten atau materi yang terkandung di
                dalam Layanan ini dapat diakses oleh Anda sesuai syarat dan
                ketentuan yang berlaku untuk Pengguna.
              </li>
              <li>
                Sepanjang relevan, pada saat Pengguna mengirimkan atau menulis
                materi ke atau pada Layanan ini, Pengguna telah setuju untuk
                memberikan kepada Danone hak non-eksklusif, berlaku di seluruh
                dunia, bebas dari kewajiban atas royalti, untuk menggunakan,
                mereproduksi, mengubah, mengadaptasi, menerbitkan,
                menerjemahkan, menciptakan karya turunan, memajang dan/atau
                menyebarkan materi tersebut ke seluruh dunia melalui Layanan
                ini, atau dengan cara yang lain tanpa ada kewajiban apa pun
                terhadap Pengguna.
              </li>
              <li>
                Semua isi dalam Layanan ini tidak dapat ditafsirkan sebagai
                izin, dengan implikasi, larangan atau lainnya, atas lisensi
                dan/atau hak atas merek dagang Danone, tanpa adanya izin
                tertulis dari Danone. Penggunaan terhadap Merek Dagang dan isi
                Layanan ini oleh Pengguna kecuali yang telah ditentukan dalam
                Syarat dan Ketentuan ini, adalah dilarang. Danone akan
                menginformasikan kepada Pengguna bahwa Danone akan
                mempertahankan hak kekayaan intelektualnya dalam Layanan ini
                beserta isinya sampai sejauh yang diperbolehkan oleh hukum,
                termasuk mengupayakan sanksi hukum.
              </li>
              <li>
                Layanan ini mungkin memuat link ke website atau Layanan yang
                lain. Kami tidak bertanggung jawab atas isi website atau Layanan
                lain atau ulasan yang sedang berlangsung pada situs web atau
                Layanan lain tersebut. Terdapatnya link ke website atau Layanan
                lain tidak menyiratkan dukungan Kami atas isinya. Jika Anda
                mengikuti link ke situs website atau Layanan lain, risiko
                ditanggung oleh Pengguna secara pribadi. Penautan ke link
                pengunduhan Layanan ini, dengan cara apa pun, tidak
                diperbolehkan.
              </li>
              <li>
                Kami tidak menyatakan atau menjamin bahwa Layanan ini, atau
                media lain seperti website manapun yang terhubung
                dengan Layanan, dapat diakses kapan saja, atau akses tersebut
                tidak terputus atau bebas kesalahan
              </li>
              <li>
                Kami berhak mengubah atau menghapus materi pada Layanan
                sewaktu-waktu berdasarkan pertimbangan Kami
              </li>
              <li>
                Kami dapat sewaktu-waktu mengubah Syarat dan Ketentuan ini
                dengan memperbarui postingan pada Layanan. Dengan mengakses
                Layanan, Anda secara hukum terikat pada revisi tersebut, itulah
                sebabnya akses Layanan ini secara berkala untuk melihat Syarat
                dan Ketentuan yang baru.
              </li>
            </ol>
            7. Bantuan dan Dukungan
            <br></br>
            Pengguna dapat menghubungi Team Leader masing-masing untuk untuk
            bantuan dan dukungan berkenaan dengan penggunaan Layanan ini.
            Pengguna dapat menghubungi tim Careline kami di nomor berikut : TBC
            <br></br>
            Sanggahan (Disclaimer)
            <br></br>
            Kami berupaya menyajikan informasi secara lengkap dan akurat.
            Namun, Kami tidak memberikan jaminan bahwa informasi dalam Layanan
            ini benar-benar lengkap, akurat, dan dapat dipercaya. Kami juga
            tidak memberikan jaminan bahwa akses ke dalam Layanan ini bebas
            hambatan, bebas kesalahan, bebas virus, atau Layanan ini
            aman. Kami tidak bertanggung jawab atas segala kerugian yang timbul
            karena tindakan yang berkaitan dengan penggunaan informasi yang
            disajikan. Risiko yang timbul karena kepercayaan Pengguna atas
            informasi yang ditampilkan dalam Layanan ini akan menjadi tanggung
            jawab Pengguna pribadi.
            <br></br>
            ****
            <br></br>
            (diubah terakhir kali pada 22 April 2021)
          </Typography>
        </Box>
      </Container>
    </Box>
  );
};

export default TermsCondition;
