import { useSnackbar } from "notistack";
import { createContext, useContext, useState } from "react";
import { useHistory } from "react-router-dom";
import { useMutation } from "react-query";

const authContext = createContext();

export function ProvideAuth({ children }) {
  const auth = useProvideAuth();
  return <authContext.Provider value={auth}>{children}</authContext.Provider>;
}

export const useAuth = () => {
  return useContext(authContext);
};

function useProvideAuth() {
  const [user, setUser] = useState(null);
  const [isAuth, setisAuth] = useState(!!localStorage.getItem("token"));
  const { enqueueSnackbar } = useSnackbar();

  const history = useHistory();

  const loginMutation = async (params) => {
    const response = await fetch(
      `${process.env.REACT_APP_BACKEND_URL}/api/auth/signin`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(params),
      }
    );

    if (!response.ok) {
      throw new Error("Error Bruh");
    } else {
      const data = await response.json();
      return data;
    }
  };

  const { mutate, isLoading } = useMutation(loginMutation, {
    onSuccess: (data) => {
      localStorage.setItem("token", data.accessToken);
      localStorage.setItem("user", data.username);
      setisAuth(true);
      setUser(data.username);
      history.push("/app/customers");
    },
    onError: () => {
      enqueueSnackbar("Kombinasi email dan password salah", {
        variant: "error",
      });
    },
  });

  const logOut = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("user");
    setisAuth(false);
    history.push("/login");
  };

  return { isAuth, user, mutate, logOut, isLoading };
}
