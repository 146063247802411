import { makeStyles, Typography } from "@material-ui/core";
import { Box } from "@material-ui/core";
import { Container } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.default,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    margin: theme.spacing(6, 0),
  },
  titleBox: {
    margin: theme.spacing(4, 0),
  },
}));

const PrivacyPolicy = () => {
  const classes = useStyles();
  return (
    <Box className={classes.root}>
      <Container maxWidth="md">
        <Box>
          <Typography align="center" color="textPrimary" variant="h2">
            Pernyataan Privasi
          </Typography>
          <Typography
            className={classes.titleBox}
            align="justify"
            color="textSecondary"
            gutterBottom
            variant="body2"
          >
            Danone tahu bahwa Anda peduli bagaimana data pribadi Anda digunakan
            dan kami menyadari pentingnya melindungi privasi Anda.
            <br></br>
            <br></br>
            Pernyataan Privasi ini menjelaskan bagaimana PT Sarihusada Generasi
            Mahardhika, berkedudukan di Yogyakarta, PT Nutricia Indonesia
            Sejahtera, berkedudukan di Jakarta Timur ("Danone") mengumpulkan dan
            mengelola data pribadi Anda serta untuk pemesanan produk data
            pribadi dikendalikan oleh pihak distributor Tigaraksa.
            <br></br>
            Pernyataan ini berisi informasi tentang data apa yang kami
            kumpulkan, bagaimana kami menggunakannya, mengapa kami
            membutuhkannya dan bagaimana hal itu bisa menguntungkan Anda.
            <br></br>
            <br></br>
            Hubungi tim Careline kami jika Anda memiliki pertanyaan dan
            komentar, atau jika Anda ingin mengajukan permintaan mengenai hak
            subyek data Anda.
            <br></br>
            <br></br>
            Pernyataan privasi ini terakhir diperbarui pada 22 April 2021.
            <br></br>
            <br></br>
            Prinsip dasar dan komitmen privasi kami
            <br></br>
            Di Danone kami berkomitmen untuk melindungi hak Anda untuk privasi.
            Kami bertujuan untuk melindungi data pribadi apa pun yang kami
            miliki, untuk mengelola data pribadi Anda secara bertanggung jawab
            dan transparan dalam praktik kami. Kepercayaan anda penting bagi
            kami. Karena itu kami berkomitmen pada prinsip-prinsip dasar
            berikut:
            <br></br>
            <ul>
              <li>
                Anda tidak memiliki kewajiban untuk memberikan data pribadi yang
                diminta oleh kami. Namun, jika Anda memilih untuk tidak
                melakukannya, kami mungkin tidak dapat menyediakan beberapa
                layanan atau produk kepada Anda;
              </li>
              <li>
                Kami hanya mengumpulkan dan memproses data Anda untuk tujuan
                yang ditetapkan dalam Pernyataan Privasi ini atau untuk tujuan
                tertentu yang kami bagikan dengan Anda dan/ atau yang telah Anda
                setujui;
              </li>
              <li>
                Kami bertujuan untuk mengumpulkan, memproses, dan menggunakan
                sesedikit mungkin data pribadi;
              </li>
              <li>
                Ketika kami mengumpulkan data pribadi Anda, kami bertujuan untuk
                menjaga data tersebut seakurat dan setepat mungkin.
              </li>
              <li>
                Jika data pribadi yang kami kumpulkan tidak lagi diperlukan
                untuk tujuan apa pun dan kami tidak diharuskan oleh hukum untuk
                menyimpannya, kami akan melakukan apa yang kami bisa untuk
                menghapus, menghancurkan, atau tidak mengidentifikasi secara
                permanen.
              </li>
              <li>
                Data pribadi Anda tidak akan dibagikan, dijual, disewakan, atau
                diungkapkan selain sebagaimana dijelaskan dalam Pernyataan
                Privasi ini.
              </li>
            </ul>
            Siapa yang mengumpulkan?
            <br></br>
            Setiap data pribadi yang diberikan kepada atau dikumpulkan oleh
            Danone dikendalikan oleh PT Sarihusada Generasi Mahardhika,
            berkedudukan di Yogyakarta, PT Nutricia Indonesia Sejahtera,
            berkedudukan di Jakarta Timur , berkedudukan di Jakarta Selatan
            sebagai pengendali data (“Danone”) serta untuk pemesanan produk data
            pribadi dikendalikan oleh pihak distributor Tigaraksa.
            <br></br>
            <br></br>
            Pernyataan Privasi ini berlaku untuk data pribadi yang dikumpulkan
            oleh Danone sehubungan dengan layanan dan produk yang kami tawarkan.
            Pernyataan ini juga berlaku untuk untuk konten pemasaran Danone,
            termasuk penawaran dan iklan untuk produk dan layanan Danone,
            kegiatan promosi Danone, yang kami (atau penyedia layanan bertindak
            atas nama kami) kirimkan kepada Anda di situs web pihak ketiga,
            platform dan aplikasi berdasarkan informasi penggunaan situs Anda.
            Situs web pihak ketiga ini umumnya memiliki Pernyataan Privasi dan
            Syarat dan Ketentuan sendiri. Kami mendorong Anda untuk membacanya
            sebelum menggunakan situs web tersebut.
            <br></br>
            <br></br>
            Data pribadi apa yang kami kumpulkan?
            <br></br>
            Data pribadi yang kami kumpulkan bervariasi tergantung pada tujuan
            pengumpulan dan produk atau layanan yang kami sediakan untuk Anda.
            <br></br>
            <br></br>
            Secara umum, kami dapat mengumpulkan jenis data pribadi berikut dari
            Anda secara langsung:
            <br></br>
            <ol type="a">
              <li>
                Data kontak pribadi, seperti nama Anda, alamat fisik, nomor
                telepon dan lokasi;
              </li>
              <li>
                Komunikasi dengan kami, yang dapat mencakup perincian percakapan
                kami melalui obrolan dan/atau saluran layanan pelanggan;
              </li>
              <li>
                Informasi demografis, seperti usia dan jenis kelamin serta
                preferensi gaya hidup Anda. Preferensi gaya hidup dapat mencakup
                preferensi Anda untuk beberapa produk yang kami tawarkan, dan
                minat Anda terkait dengan produk-produk tersebut;
              </li>
              <li>
                Riwayat browser, seperti halaman yang diakses, tanggal akses,
                lokasi ketika diakses, dan alamat IP;
              </li>
            </ol>
            Mengapa kami mengumpulkan dan menggunakan data pribadi Anda?
            <br></br>
            Kami mengumpulkan data pribadi Anda agar kami dapat memberi Anda
            pengalaman online terbaik dan memberikan Anda layanan pelanggan yang
            berkualitas tinggi. Secara khusus kami dapat mengumpulkan,
            menyimpan, menggunakan, dan mengungkapkan data pribadi Anda untuk
            tujuan berikut:
            <ol type="a">
              <li>
                Untuk memberikan produk informasi kepada Anda melalui Careline
                Danone yang akan menghubungi melalui Whatsapp Business
              </li>
              <li>
                Menjawab dan menerima pesanan produk Danone dari pengguna dengan
                menggunakan fitur otomatis (“Chatbot”) melalui media komunikasi
                nomor WhatsApp Business
              </li>
              <li>
                Untuk memproses pemesanan dan pembayaran Anda ke pihak
                Distributor Tigaraksa, jika Anda membeli produk kami, untuk
                menyediakan Anda dengan status pesanan Anda, berurusan dengan
                pertanyaan dan permintaan Anda, dan menilai dan menangani
                keluhan;
              </li>
              <li>
                Untuk memproses dan menjawab pertanyaan Anda atau untuk
                menghubungi Anda untuk menjawab pertanyaan dan/atau permintaan
                Anda;
              </li>
              <li>
                Untuk mengotentikasi/memverifikasi identitas orang yang
                menghubungi kami melalui telepon, alat elektronik atau lainnya;
              </li>
              <li>Untuk pelatihan internal dan tujuan jaminan kualitas;</li>
              <li>
                Untuk memahami dan menilai minat, keinginan, dan perubahan
                kebutuhan pelanggan, untuk meningkatkan situs web kami, produk
                dan layanan kami saat ini, dan/ atau mengembangkan produk dan
                layanan baru;
              </li>
              <li>
                Untuk menyediakan produk dan komunikasi yang dipersonalisasi
                serta rekomendasi produk kepada Anda pelanggan.
              </li>
            </ol>
            Produk dan layanan yang dimaksud disini adalah seluruh produk
            Danone.
            <br></br>
            <br></br>
            Kami juga mungkin memerlukan data pribadi Anda untuk mematuhi
            kewajiban hukum atau dalam konteks hubungan kontraktual yang kami
            miliki dengan Anda.
            <br></br>
            Ketika kami mengumpulkan dan menggunakan data pribadi Anda untuk
            tujuan yang disebutkan di atas atau untuk tujuan lain, kami akan
            memberi tahu Anda sebelum atau pada saat pengumpulan.
            <br></br>
            <br></br>
            Apabila diperlukan, kami akan meminta persetujuan Anda untuk
            memproses data pribadi. Di mana Anda telah memberikan persetujuan
            untuk kegiatan pemrosesan, Anda memiliki hak untuk menarik
            persetujuan Anda kapan saja.
            <br></br>
            <br></br>
            Hak Anda
            <br></br>
            Saat kami memproses data pribadi Anda, Anda berhak atas sejumlah hak
            dan dapat menggunakan hak-hak ini kapan saja. Kami telah memberikan
            ikhtisar tentang hak-hak di bawah ini bersama dengan apa yang
            terkandung di dalamnya bagi Anda. Anda dapat menggunakan hak Anda
            dengan menghubungi tim Careline kami.
            <br></br>
            <br></br>
            Hak untuk mengakses data pribadi Anda dan koreksi
            <br></br>
            Anda memiliki hak untuk mengakses, memperbaiki, atau memperbarui
            data pribadi Anda kapan saja. Kami memahami pentingnya hal ini dan
            jika Anda ingin menggunakan hak Anda, silakan hubungi tim Careline
            <br></br>
            <br></br>
            Hak portabilitas data
            <br></br>
            Data pribadi Anda portabel. Ini berarti dapat dipindahkan, disalin,
            atau ditransmisikan secara elektronik. Namun, hak ini hanya berlaku
            jika:
            <ol type="a">
              <li>Pemrosesan didasarkan pada persetujuan Anda;</li>
              <li>Proses berlangsung untuk pelaksanaan kontrak;</li>
              <li>Proses berlangsung dengan cara otomatis;</li>
            </ol>
            Jika Anda ingin menggunakan hak Anda untuk portabilitas data,
            silakan hubungi tim Careline kami.
            <br></br>
            <br></br>
            Hak untuk menghapus data pribadi Anda.
            <br></br>
            Anda berhak meminta kami menghapus data Anda jika:
            <ol type="a">
              <li>
                data pribadi Anda tidak lagi diperlukan sehubungan dengan tujuan
                yang kami kumpulkan; atau
              </li>
              <li>
                Anda menarik persetujuan yang sebelumnya Anda berikan kepada
                kami untuk memproses data pribadi Anda, dan tidak ada dasar
                hukum lain untuk memproses data pribadi itu; atau
              </li>
              <li>
                Anda keberatan jika kami memproses data pribadi Anda untuk
                tujuan pemasaran langsung; atau
              </li>
              <li>
                Anda keberatan kami memproses data pribadi Anda untuk
                kepentingan sah Danone (seperti meningkatkan pengalaman pengguna
                secara keseluruhan di situs web);
              </li>
              <li>data pribadi tidak diproses secara sah; atau</li>
              <li>data pribadi Anda perlu dihapus untuk mematuhi hukum.</li>
              <li>
                Jika Anda ingin menghapus data pribadi yang kami miliki tentang
                Anda, silakan hubungi tim Careline kami dan kami akan mengambil
                langkah-langkah yang wajar untuk menanggapi permintaan Anda
                sesuai dengan persyaratan hukum.
              </li>
              <li></li>
            </ol>
            Jika data pribadi yang kami kumpulkan tidak lagi diperlukan untuk
            tujuan apa pun dan kami tidak diharuskan oleh hukum untuk
            menyimpannya, kami akan melakukan apa yang kami bisa untuk
            menghapus, menghancurkan, atau tidak mengidentifikasi secara
            permanen.
            <br></br>
            <br></br>
            Hak atas pembatasan pemrosesan
            <br></br>
            Anda berhak membatasi pemrosesan data pribadi Anda jika :<br></br>
            <ol type="a">
              <li>
                Anda tidak percaya bahwa data pribadi yang kami miliki tentang
                Anda adalah akurat; atau
              </li>
              <li>
                data pribadi tidak diproses secara sah, tetapi alih-alih
                menghapus data pribadi, Anda lebih suka kami membatasi
                pemrosesan sebagai gantinya; atau
              </li>
              <li>
                kami tidak lagi membutuhkan data pribadi Anda untuk tujuan kami
                mengumpulkannya, tetapi Anda memerlukan data untuk membuat,
                melaksanakan atau mempertahankan klaim hukum; atau
              </li>
              <li>
                Anda berkeberatan dengan pemrosesan data pribadi Anda dan sedang
                menunggu verifikasi apakah minat Anda yang terkait dengan
                keberatan tersebut lebih besar daripada alasan yang sah untuk
                memproses data Anda.
              </li>
            </ol>
            <br></br>
            Jika Anda ingin membatasi pemrosesan data pribadi Anda, beri tahu
            kami dengan menghubungi tim Careline kami dan kami akan mengambil
            langkah-langkah yang wajar untuk menanggapi permintaan Anda sesuai
            dengan persyaratan hukum.
            <br></br>
            <br></br>
            Hak untuk menolak
            <br></br>
            Anda memiliki hak untuk menolak pemrosesan data pribadi Anda kapan
            saja. Silahkan hubungi tim Careline kami.
            <br></br>
            <br></br>
            Hak untuk mengajukan pengaduan ke otoritas pengawas
            <br></br>
            Anda memiliki hak untuk mengajukan pengaduan langsung ke otoritas
            pengawas tentang bagaimana kami memproses data pribadi.
            <br></br>
            <br></br>
            Bagaimana kami melindungi data pribadi Anda
            <br></br>
            Kami memahami bahwa keamanan data pribadi Anda penting. Kami
            melakukan upaya terbaik kami untuk melindungi data pribadi Anda dari
            penyalahgunaan, gangguan, kehilangan, akses tidak sah, modifikasi,
            atau pengungkapan. Kami telah menerapkan sejumlah langkah keamanan
            untuk membantu melindungi data pribadi Anda. Misalnya, kami
            menerapkan kontrol akses, menggunakan firewall dan server yang aman,
            dan kami mengenkripsi data pribadi.
            <br></br>
            <br></br>
            Berbagi data pribadi Anda
            <br></br>
            Saat kami membagikan data pribadi Anda dengan afiliasi dan
            organisasi lain, kami memastikan kami hanya melakukannya dengan
            organisasi yang melindungi dan melindungi data pribadi Anda dan
            mematuhi undang-undang privasi yang berlaku dengan cara yang sama
            atau serupa yang kami lakukan.
            <br></br>
            <br></br>
            Data pribadi Anda tidak akan dibagikan, dijual, disewakan, atau
            diungkapkan selain sebagaimana dijelaskan dalam Pernyataan Privasi
            ini. Namun, kami dapat membagikan data Anda untuk keperluan hukum
            dan/atau saat diminta otoritas pemerintah.
            <br></br>
            <br></br>
            Berbagi data secara internasional
            <br></br>
            Data pribadi dapat diproses di luar wilayah Republik Indonesia. Saat
            diproses di luar wilayah Republik Indonesia, Danone akan memastikan
            bahwa pemrosesan data lintas batas ini dilindungi oleh pengamanan
            yang memadai.
            <br></br>
            <br></br>
            Perlindungan yang kami gunakan untuk melindungi pemrosesan data
            lintas batas meliputi:
            <br></br>
            sertifikasi yang menunjukkan bahwa pihak ketiga di luar wilayah
            Republik Indonesia memproses data pribadi sedemikian rupa sehingga
            konsisten dengan Peraturan Perlindungan Data di Republik Indonesia.
            Sertifikasi ini disetujui baik oleh otoritas pengawas yang kompeten
            atau badan akreditasi nasional yang kompeten dalam hal Peraturan
            Perlindungan Data.
            <br></br>
            <br></br>
            Pengambilan keputusan dan pembuatan profil otomatis
            <br></br>
            Untuk beberapa layanan dan produk, kami dapat memproses data pribadi
            Anda menggunakan cara otomatis. Pada dasarnya ini berarti bahwa
            keputusan diambil secara otomatis tanpa campur tangan manusia.
            <br></br>
            <br></br>
            Kami juga dapat memproses data pribadi Anda untuk tujuan pembuatan
            profil guna memprediksi perilaku Anda di situs web atau produk kami
            yang mungkin menarik bagi Anda.
            <br></br>
            <br></br>
            Jika kami bermaksud menggunakan metode tersebut, kami tentu saja
            akan memberi tahu Anda dan kami akan memberi Anda kesempatan untuk
            menolak proses ini sebelumnya. Anda juga bebas untuk menghubungi tim
            Careline kami untuk informasi lebih lanjut tentang pemrosesan
            tersebut.
            <br></br>
            <br></br>
            Bagaimana cara menghubungi kami
            <br></br>
            Jika Anda memiliki pertanyaan, komentar atau keluhan mengenai
            Pernyataan Privasi ini atau pemrosesan data pribadi Anda, silakan
            hubungi tim Careline kami di nomor berikut 081280023972
            <br></br>
            <br></br>
            Bagaimana cara kami memperbarui Pernyataan ini?
            <br></br>
            Kami akan memperbarui Pernyataan Privasi ini bila perlu untuk
            memenuhi permintaan pelanggan dan menyesuaikan perubahan dalam
            produk dan layanan kami. Ketika kami memposting perubahan pada
            pernyataan ini, kami akan merevisi tanggal "terakhir diperbarui" di
            bagian atas Pemberitahuan ini. Jika perubahannya signifikan, kami
            akan memberikan pemberitahuan yang lebih menonjol (termasuk, untuk
            layanan tertentu, pemberitahuan email tentang perubahan
            Pemberitahuan Privasi).
            <br></br>
            <br></br>
            Ketentuan atau Pernyataan Privasi Tambahan
            <br></br>
            Selain Pernyataan Privasi ini, mungkin ada kampanye atau promosi
            khusus yang akan diatur oleh persyaratan atau pernyataan privasi
            tambahan. Kami menyarankan Anda untuk membaca ketentuan atau
            pemberitahuan tambahan ini sebelum berpartisipasi dalam kampanye
            atau promosi apa pun karena Anda harus mematuhinya jika Anda ingin
            berpartisipasi. Ketentuan atau pernyataan privasi tambahan apa pun
            akan tersedia bagi Anda secara jelas selama periode kampanye atau
            promosi.
            <br></br>
            ***
          </Typography>
        </Box>
      </Container>
    </Box>
  );
};

export default PrivacyPolicy;
