import React, { useEffect, useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  Input,
  ListItemIcon,
  TextField,
} from "@material-ui/core";
import { useSnackbar } from "notistack";
import {
  focusManager,
  useMutation,
  useQuery,
  useQueryClient,
} from "react-query";
import { format, parse as parseDate } from "date-fns";
import moment from "moment";
// components
import PageTitle from "../../components/PageTitle/PageTitle";
import {
  List,
  ListItem,
  ListItemText,
  CircularProgress,
} from "@material-ui/core";
import AddItrDialog from "../../components/Dialog/AddItrDialog";
import AssignmentSharpIcon from "@material-ui/icons/AssignmentSharp";
import EnhancedTable from "../../components/Tables/EnhancedTable";
import { Box } from "@material-ui/core";
import { Paper } from "@material-ui/core";
import { parse } from "papaparse";
import axios from "axios";
import { CheckCircle, ErrorRounded, Refresh } from "@material-ui/icons";
import CreateDialog from "../../components/Dialog/CreateDialog";
import SendMenuDialog from "../../components/Dialog/SendMenuDialog";

export default function Tables() {
  const queryClient = useQueryClient();
  const columns = React.useMemo(
    () => [
      {
        Header: "Username",
        accessor: "username",
        // disableGlobalFilter: true,
      },
      {
        Header: "Nomor Telepon",
        accessor: "phoneNumber",
      },
      {
        Header: "Produk Rekomendasi",
        accessor: "productName",
        Cell: ({ row }) => (
          <List>
            {row?.original?.productName?.map((value, index) => {
              const [name, date] = value.split("|");

              return (
                <ListItem key={index}>
                  <ListItemText primary={name} />
                  <ListItemText
                    primary={moment(date)
                      .add(7, "h")
                      .format("DD/MM/YYYY HH:mm")}
                    style={{ textAlign: "end" }}
                  />
                </ListItem>
              );
            })}
          </List>
        ),
      },
      {
        Header: "Hospital Name",
        accessor: "hospitalname",
        // disableGlobalFilter: true,
      },
      {
        Header: "Hospital ID",
        accessor: "hospitalid",
      },
      {
        Header: "Action",
        Cell: ({ row }) => <AddItrDialog data={row.original} />,
      },
    ],
    []
  );

  const [openImportDialog, setOpenImportDialog] = useState(false);
  const [exportIsWaiting, setExportIsWaiting] = useState(false);
  const [importIsWaiting, setImportIsWaiting] = useState(false);
  const [createDialogOpen, setCreateDialogOpen] = useState(false);
  const [sendMenuDialogOpen, setSendMenuDialogOpen] = useState(false);
  const [importErrorMessages, setimportErrorMessages] = useState([]);
  const [dateRange, setDateRange] = useState([null, null]);
  const [dateFilter, setDateFilter] = useState({ from: null, to: null });

  const { enqueueSnackbar } = useSnackbar();

  const baseUrl = `${process.env.REACT_APP_BACKEND_URL}`;

  const getAllData = async (filter) => {
    console.log("date filter", filter);

    const urlParams =
      filter.from && filter.to ? new URLSearchParams(filter).toString() : "";

    const response = await fetch(`${baseUrl}/api/itr/itrs?` + urlParams, {
      method: "GET",
    });

    const data = await response.json();
    return data.customers;
  };

  const { data, isLoading } = useQuery(["getAllData", dateFilter], () =>
    getAllData(dateFilter)
  );

  useEffect(() => {
    const [start, to] = dateRange;

    if (start && to) {
      setDateFilter({
        from: format(start, "yyyy-MM-dd"),
        to: format(to, "yyyy-MM-dd"),
      });
    }

    if (!start && !to) {
      setDateFilter({
        from: null,
        to: null,
      });
    }
  }, [dateRange, queryClient]);

  const getCsvRecords = async () => {
    setExportIsWaiting(true);
    const response = await fetch(
      `${process.env.REACT_APP_BACKEND_URL}/api/itr/export`,
      {
        method: "GET",
      }
    );

    const data = await response.blob();

    if (data) {
      setExportIsWaiting(false);
    }

    const file = window.URL.createObjectURL(data);
    window.location.assign(file);
  };

  const importFromCSVLocally = async (e) => {
    const importFile = e.target.files;
    console.log(importFile);
    if (importFile) {
      // setImportIsWaiting(true)
      try {
        setOpenImportDialog(true);
        const csvFile = Array.from(importFile).filter(
          (file) =>
            file.type === "text/csv" || file.type === "application/vnd.ms-excel"
        );
        if (csvFile.length !== 0) {
          const logs = [];
          csvFile.forEach(async (file) => {
            const columns = await file.text();
            console.log({ columns });
            const result = parse(columns, {
              header: true,
              fastMode: true,
              // worker: true,
            });
            // return console.log(result)
            if (result.data.length > 200) {
              logs.push({
                data: { msg: "Data import is limited to 200 data" },
              });
              return setimportErrorMessages([...logs]);
            } else {
              console.log(
                "🚀 ~ file: Table.js ~ line 127 ~ csvFile.forEach ~ result",
                result
              );
              result.data.forEach((item) => {
                console.log(item);
                function pushLogs(response) {
                  logs.push(response);
                  setimportErrorMessages([...logs]);
                }
                axios
                  .put(`${baseUrl}/api/user/update`, JSON.stringify(item), {
                    headers: {
                      "Content-Type": "application/json",
                    },
                  })
                  .then((response) => {
                    pushLogs(response);
                  })
                  .catch((error) => {
                    pushLogs(error.response);
                  });
                // setContacts((existing) => [...existing, ...result.data]);
                // setimportErrorMessages(...importErrorMessages, response)
              });
            }
          });
          console.log({ logs, importErrorMessages });
          return logs;
          // setImportIsWaiting(false)
        } else {
          const errorMessage = "File Invalid";
          console.log(errorMessage);
          enqueueSnackbar(errorMessage, { variant: "error" });
          return errorMessage;
        }
      } catch (error) {
        e.target.files = null;
        console.log(error);
      }
    }
  };

  const importFromCSV = async (e) => {
    const importFile = e.target.files;
    console.log(importFile);
    if (importFile) {
      setImportIsWaiting(true);
      console.log(importFile[0]);
      try {
        const formData = new FormData();
        formData.append("file", importFile[0]);
        axios
          .post(`${baseUrl}/api/user/import`, formData)
          .then((response) => {
            console.log(response);
            setImportIsWaiting(false);
            enqueueSnackbar("Data imported", { variant: "success" });
            return response;
          })
          .catch((error) => {
            setImportIsWaiting(false);
            return console.log(error);
            // alert(error.response.data.message)
          });
      } catch (error) {
        // alert(error)
        setImportIsWaiting(false);
        console.log(error);
        return error;
      }
    }
    const formData = new FormData();
    formData.append("file", importFile);
  };
  const { status: statusImport, mutateAsync: mutateImport } = useMutation(
    importFromCSVLocally,
    {
      onSuccess: () => {
        // alert('Success')
        setImportIsWaiting(false);
        queryClient.invalidateQueries("getAllData");
      },
      onMutate: () => {
        setImportIsWaiting(true);
      },
    }
  );

  const handleImport = (e) => {
    console.log(e);
    e.persist();
    mutateImport(e);
  };
  // const { mutateAsync: isLoadingImport, data: dataImport } = useMutation('importCSV', importFromCSVLocally)

  const [tableData, setTableData] = React.useState([[]]);
  const [skipPageReset, setSkipPageReset] = React.useState(false);

  React.useEffect(() => {
    if (data) {
      setTableData(data);
    }
  }, [data]);

  const updateMyData = (rowIndex, columnId, value) => {
    // We also turn on the flag to not reset the page
    setSkipPageReset(true);
    setTableData((old) =>
      old.map((row, index) => {
        if (index === rowIndex) {
          return {
            ...old[rowIndex],
            [columnId]: value,
          };
        }
        return row;
      })
    );
  };

  return (
    <>
      <Dialog
        open={openImportDialog}
        onClose={() => setOpenImportDialog(false)}
      >
        <DialogTitle>Import Status</DialogTitle>
        <DialogContent>
          <DialogContentText>
            <List dense={false}>
              {/* {statusImport === 'idle' && <CircularProgress />} */}
              {/* {statusImport} */}
              {importErrorMessages &&
                importErrorMessages.map((item, key) => {
                  return (
                    <ListItem>
                      {item.status === 200 ? (
                        <>
                          <ListItemIcon>
                            <CheckCircle />
                          </ListItemIcon>
                          <ListItemText>
                            Column {key + 1}: <span>Success</span>
                          </ListItemText>
                        </>
                      ) : (
                        <>
                          <ListItemIcon>
                            <ErrorRounded />
                          </ListItemIcon>
                          <ListItemText>
                            Column {key + 1}: <span>{item.data.msg}</span>
                          </ListItemText>
                        </>
                      )}
                    </ListItem>
                  );
                })}
            </List>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          {/* <Button variant="outlined" onClick={() => setOpenImportDialog(false)} color={'primary'}>
            Dismiss
          </Button> */}
          {
            <Button
              variant="contained"
              color={"primary"}
              style={{ display: "flex", justifyContent: "center", gap: "2em" }}
              onClick={() => window.location.reload()}
            >
              <Refresh /> Reload Page
            </Button>
          }
        </DialogActions>
      </Dialog>
      <CreateDialog
        open={createDialogOpen}
        onClose={() => setCreateDialogOpen(false)}
      />
      <SendMenuDialog
        open={sendMenuDialogOpen}
        onClose={() => setSendMenuDialogOpen(false)}
      />
      <Grid
        container
        direction="row"
        justify="space-between"
        alignItems="center"
      >
        <Grid item xs={6}>
          <PageTitle title="Customers" />
        </Grid>
        <Grid
          item
          xs={6}
          style={{
            display: "flex",
            gap: "1em",
            justifyContent: "flex-end",
          }}
        >
          <Button
            startIcon={<AssignmentSharpIcon />}
            color="primary"
            variant="contained"
            size="large"
            onClick={() => setSendMenuDialogOpen(true)}
          >
            Send Menu
          </Button>
          <Button
            startIcon={<AssignmentSharpIcon />}
            color="primary"
            variant="contained"
            size="large"
            disabled={exportIsWaiting}
            onClick={() => setCreateDialogOpen(true)}
          >
            Create Record
          </Button>
          <Button
            startIcon={<AssignmentSharpIcon />}
            color="primary"
            variant="contained"
            size="large"
            disabled={exportIsWaiting}
            onClick={getCsvRecords}
          >
            {exportIsWaiting ? "Downloading" : "Export Data"}
          </Button>
          <label htmlFor="contained-button-file">
            <Input
              inputProps={{ accept: ".csv" }}
              onChange={(e) => handleImport(e)}
              style={{ display: "none" }}
              id="contained-button-file"
              type="file"
            />
            <Button
              size="large"
              disabled={importIsWaiting}
              color="primary"
              variant="contained"
              component="span"
            >
              {!importIsWaiting ? "Import Data" : "Importing"}
            </Button>
          </label>
        </Grid>
      </Grid>

      <Grid container spacing={4}>
        <Grid item xs={12}>
          {isLoading ? (
            <Box display="flex" justifyContent="center" alignItems="center">
              <CircularProgress />
            </Box>
          ) : (
            <Paper>
              <EnhancedTable
                columns={columns}
                data={tableData}
                setData={setTableData}
                updateMyData={updateMyData}
                skipPageReset={skipPageReset}
                dateRange={dateRange}
                setDateRange={setDateRange}
              />
            </Paper>
          )}
        </Grid>
      </Grid>
    </>
  );
}
