/* eslint-disable operator-linebreak */
import React, { useEffect, useState } from "react";
import CreateSharpIcon from "@material-ui/icons/CreateSharp";
import SendSharpIcon from "@material-ui/icons/SendSharp";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import TextField from "@material-ui/core/TextField";
import Tooltip from "@material-ui/core/Tooltip";
import { useQueryClient, useMutation, useQuery } from "react-query";
import { useSnackbar } from "notistack";
import { Box, IconButton, Tab, CircularProgress } from "@material-ui/core";
import { Autocomplete, TabContext, TabList, TabPanel } from "@material-ui/lab";
import { Typography } from "@material-ui/core";
import { Chip } from "@material-ui/core";
import axios from "axios";

const AddItrDialog = (props) => {
  let initialState = {
    productName: [],
    username: "",
    phoneNumber: "",
    hospitalid: "",
  };

  const { username, phoneNumber, productName, hospitalid } = props.data;

  const [recomend, setRecomend] = useState(initialState);
  const [products, setProducts] = useState([]);
  const [userInfo, setUserInfo] = useState({
    hospitalid: "",
    username: "",
    customerId: "",
    phoneNumber: "",
  });
  const [open, setOpen] = useState(false);
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    setUserInfo({
      hospitalid: hospitalid,
      phoneNumber: phoneNumber,
      username: username,
      customerId: phoneNumber,
    });
  }, [hospitalid, username, productName, phoneNumber]);

  useEffect(() => {
    setRecomend({
      username,
      hospitalid,
      productName,
      phoneNumber,
    });
  }, [username, hospitalid, productName, phoneNumber]);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setProducts([]);
    setOpen(false);
  };

  const handleAdd = () => {
    try {
      // mutateChangeUserInfo(userInfo)
      // changeUserInfo()
      mutateRecomend(recomend);
      setRecomend(initialState);
      handleClose();
    } catch (error) {
      console.log(`Handle Add Error: ${error}`);
    }
  };

  const handleChangeUserInfo = () => {
    try {
      mutateChangeUserInfo(userInfo);
    } catch (error) {
      console.log(`Handle Change User Info Error: ${error}`);
    }
  };

  const handleChange = (value) => {
    let productName = value?.name;
    let productId = value?.id;

    console.log(value);
    console.log(products);

    if (!productId) return;
    if (products.find((item) => item.productId === Number(productId))) return;

    setProducts((old) => old.concat({ productName, productId }));
  };

  const getData = (value) => {
    return queryClient.invalidateQueries("getAllData");
  };

  const putItrData = async () => {
    const response = await fetch(
      `${process.env.REACT_APP_BACKEND_URL}/api/itr/update`,
      {
        method: "PUT",
        headers: {
          "Content-type": "application/json",
        },
        body: JSON.stringify({ username: recomend.phoneNumber, products }),
      }
    );

    const data = await response.json();

    return data;
  };

  const changeUserInfo = async () => {
    console.log(userInfo);
    try {
      const getData = await axios.put(
        `${process.env.REACT_APP_BACKEND_URL}/api/user/update`,
        JSON.stringify(userInfo),
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      console.log(getData);
      mutateGetData();
      enqueueSnackbar("User info updated", { variant: "success" });
      handleClose();
      return getData;
    } catch (error) {
      enqueueSnackbar(error.response.data.msg, { variant: "error" });
      console.log(error);
      return error;
    }

    // fetch(
    //   `http://localhost:8080/api/user/update`,
    //   {
    //     method: "PUT",
    //     headers: {
    //       "Content-Type": "application/json",
    //     },
    //     body: JSON.stringify(userInfo)
    //   }
    // ).then((response) => {
    //   const data = response.json();
    //   console.log(response)
    //   return data
    // }).catch((error) => {
    //   console.log(error)
    //   return alert(error.response.json())
    // })
  };

  // const { mutate } = useMutation(changeUserInfo, {
  //   onSuccess: () => {
  //     enqueueSnackbar("User Info updated", { variant: "success" })
  //   },
  //   onError: (e) => {
  //     console.log(e)
  //   }

  // })

  const { mutate: mutateRecomend, isLoading: isLoadingRecomend } = useMutation(
    putItrData,
    {
      onSuccess: () => {
        queryClient.invalidateQueries("getAllData");
        enqueueSnackbar("Itr Added", { variant: "success" });
      },
    }
  );

  const { mutate: mutateChangeUserInfo, isLoading: isLoadingChangeUserInfo } =
    useMutation(changeUserInfo, {
      onSuccess: () => {},
      onError: () => {},
    });

  const { mutate: mutateGetData, isLoading: isLoadingGetData } =
    useMutation(getData);

  const getAllProducts = async () => {
    const response = await fetch(
      `${process.env.REACT_APP_BACKEND_URL}/api/itr/products`,
      {
        method: "GET",
      }
    );

    const data = await response.json();

    return data.products;
  };

  const { data } = useQuery("getAllProducts", getAllProducts);

  const [valueTabs, setValueTabs] = React.useState("User Info");

  const handleChangeTabs = (event, newValue) => {
    setValueTabs(newValue);
  };

  return (
    <div>
      <Tooltip title="Tambah Rekomendasi Produk">
        <IconButton color="primary" onClick={handleClickOpen}>
          <CreateSharpIcon />
        </IconButton>
      </Tooltip>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        {isLoadingGetData ? (
          <DialogContent>
            <CircularProgress />
          </DialogContent>
        ) : (
          <TabContext value={valueTabs}>
            <DialogTitle id="form-dialog-title">Produk Rekomendasi</DialogTitle>
            <DialogContent style={{ padding: "0" }}>
              <Box sx={{ width: "100%" }}>
                <TabList onChange={handleChangeTabs}>
                  <Tab label={"User Info"} value={"User Info"} />
                  <Tab
                    label={"Product Recommendation"}
                    value={"Product Recommendation"}
                  />
                </TabList>

                <TabPanel value={"User Info"}>
                  <TextField
                    margin={"normal"}
                    label="Hospital ID"
                    type="text"
                    fullWidth
                    defaultValue={
                      userInfo.hospitalid ? userInfo.hospitalid : "Empty"
                    }
                    disabled
                  />
                  <TextField
                    autoFocus
                    margin="normal"
                    label="Username"
                    type="text"
                    fullWidth
                    defaultValue={userInfo.username}
                    onChange={(e) =>
                      setUserInfo({ ...userInfo, username: e.target.value })
                    }
                  />
                  <TextField
                    margin="normal"
                    label="Nomor Telepon"
                    type="text"
                    inputProps={{ inputMode: "numeric", pattern: "[0-9]*" }}
                    fullWidth
                    defaultValue={userInfo.phoneNumber}
                    onChange={(e) => {
                      const value = e.target.value;
                      setUserInfo({
                        ...userInfo,
                        phoneNumber: value,
                        customerId: value,
                      });
                    }}
                    // disabled
                  />
                </TabPanel>
                <TabPanel value={"Product Recommendation"}>
                  <Typography gutterBottom variant="body2">
                    Produk yang sudah direkomendasi:
                  </Typography>

                  {typeof recomend.productName !== "undefined" &&
                    recomend.productName.map((product) => {
                      const [productName] = product.split("|");
                      return (
                        <Chip
                          style={{ margin: "5px" }}
                          label={productName}
                          key={productName}
                          color="default"
                        ></Chip>
                      );
                    })}

                  {products.map((item) => (
                    <Chip
                      style={{ margin: "5px" }}
                      label={item.productName}
                      key={item.productId}
                      onDelete={() =>
                        setProducts((olds) =>
                          olds.filter((old) => old.productId !== item.productId)
                        )
                      }
                      color="primary"
                    ></Chip>
                  ))}

                  <Autocomplete
                    id="tags-standard"
                    options={data}
                    getOptionLabel={(option) => option.name}
                    onChange={(_, newValue) => {
                      handleChange(newValue);
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="standard"
                        label="Tambah Produk"
                        placeholder="Rekomendasi"
                      />
                    )}
                  />
                </TabPanel>
              </Box>
            </DialogContent>
            <DialogActions>
              <TabPanel value="User Info">
                <Button
                  onClick={handleChangeUserInfo}
                  variant={"contained"}
                  color={"primary"}
                  disabled={isLoadingChangeUserInfo}
                >
                  Update
                </Button>
              </TabPanel>
              <TabPanel value={"Product Recommendation"}>
                <Box sx={{ width: "100%" }}>
                  <Button onClick={handleClose} color="secondary">
                    Cancel
                  </Button>
                  <Button
                    startIcon={<SendSharpIcon />}
                    onClick={handleAdd}
                    color="primary"
                    disabled={isLoadingRecomend}
                  >
                    {isLoadingRecomend ? "Loading" : "Add"}
                  </Button>
                </Box>
              </TabPanel>
            </DialogActions>
          </TabContext>
        )}
      </Dialog>
    </div>
  );
};

export default AddItrDialog;
