import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import { useAuth } from "../context/AuthContext";
import Login from "../pages/auth/Login";
import Register from "../pages/auth/Register";
import PrivacyPolicy from "../pages/general/PrivacyPolicy";
import TermsCondition from "../pages/general/TermsCondition";

// components
import Layout from "./Layout/Layout";

export default function App() {
  // global
  const { isAuth } = useAuth();

  return (
    <Switch>
      <Route
        exact
        path="/app"
        render={() => <Redirect to="/app/customers" />}
      />
      <Route exact path="/" render={() => <Redirect to="/login" />} />
      <PrivateRoute path="/app" component={Layout} />
      <PublicRoute path="/login" exact component={Login} />
      <PublicRoute path="/register" exact component={Register} />
      <Route path="/term-and-condition" exact component={TermsCondition} />
      <Route path="/privacy-policy" exact component={PrivacyPolicy} />
    </Switch>
  );

  function PublicRoute({ component, ...rest }) {
    return (
      <Route
        {...rest}
        render={(props) =>
          isAuth ? (
            <Redirect
              to={{
                pathname: "/app",
              }}
            />
          ) : (
            React.createElement(component, props)
          )
        }
      />
    );
  }

  function PrivateRoute({ component, ...rest }) {
    return (
      <Route
        {...rest}
        render={(props) =>
          isAuth ? (
            React.createElement(component, props)
          ) : (
            <Redirect
              to={{
                pathname: "/login",
                state: {
                  from: props.location,
                },
              }}
            />
          )
        }
      />
    );
  }
}
