import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from "@material-ui/core";
import axios from "axios";
import { useSnackbar } from "notistack";
import { useState } from "react";

const SendMenuDialog = (props) => {
  const { enqueueSnackbar } = useSnackbar();
  const [user, setUser] = useState({
    phoneNumber: "",
  });

  const handleSubmit = async () => {
    console.log(user);

    try {
      const sendMenuResponse = await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/api/sendMenu`,
        user,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      console.log(sendMenuResponse.data);
      enqueueSnackbar("Menu has been sent", { variant: "success" });
      props.onClose();
    } catch (err) {
      enqueueSnackbar(err.response.data.message, { variant: "error" });
      console.log(err);
      return err;
    }
  };

  return (
    <Dialog open={props.open} onClose={props.onClose}>
      <DialogTitle>Kirim Menu</DialogTitle>
      <DialogContent>
        <Box sx={{ width: "100%" }}>
          <TextField
            autoFocus
            margin={"normal"}
            label="Phone Number"
            type="number"
            fullWidth
            style={{ width: "300px" }}
            onChange={(e) => setUser({ ...user, phoneNumber: e.target.value })}
          />
        </Box>
      </DialogContent>
      <DialogActions
        style={{
          padding: "30px",
        }}
      >
        <Button
          onClick={handleSubmit}
          disabled={false}
          variant={"contained"}
          color={"primary"}
        >
          Send
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default SendMenuDialog;
